/* Main Color: #00bff2 
Shades: #edfbff
#ccf2fc
Tints: 
#006079



*/

/* ////////////////////////////////////////////////////////// */
/* General CSS */
/* ////////////////////////////////////////////////////////// */

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

html {
  /* font-size: 10px; */
  font-size: 62.5%;
  overflow-x: hidden;
  scroll-behavior: smooth;
}

body {
  font-family: Epilogue, sans-serif;
  line-height: 1;
  font-weight: 400;
  color: #000;
  overflow-x: hidden;
  background-color: #edfbff;
}

h2 {
  font-size: 3rem;
  padding: 0 0 4.4rem 8rem;
  font-weight: 700;
}

h3 {
  font-size: 2.4rem;
  font-weight: 700;
  padding-top: 1.2rem;
}

h4 {
  font-size: 2rem;
}

.section {
  transition: transform 0.3s, opacity 1s;
}

.section--hidden {
  opacity: 0;
  transform: translateY(8rem);
}

/* ////////////////////////////////////////////////////////// */
/* Header and Nav Bar */
/* ////////////////////////////////////////////////////////// */

.btn-mobile-nav {
  display: none;
}

.header {
  display: flex;
  justify-content: space-between;
  position: relative;
}

.nav {
  height: 6.4rem;
  display: flex;
  align-items: center;
  padding: 0 4.8rem;
}

.logo {
  height: 6.2rem;
  padding: 0.8rem;
  padding-left: 2.4rem;
}

.nav-items {
  list-style: none;
  display: flex;
  gap: 4.8rem;
  text-transform: uppercase;
}

.header.sticky {
  position: fixed;
  width: 100%;
  background-color: #edfbff;
  z-index: 99999999;
  border-bottom: 1px solid #929497;
}

.nav-item:link,
.nav-item:visited {
  font-size: 2.4rem;
  text-decoration: none;
  color: #004c61;
  font-weight: 500;
  letter-spacing: 1px;
}
.nav-item:hover,
.nav-item:active {
  color: #001318;
}
/* ////////////////////////////////////////////////////////// */
/* Hero Section */
/* ////////////////////////////////////////////////////////// */
.hero-section {
  max-width: 90vw;
  margin: 0 auto;
  padding: 4.8rem 0 9.6rem 0;
}

.hero {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 9.6rem;
  padding: 4.4rem 3.2rem;
  align-items: start;
  justify-items: center;
}

.hero-graphic-box {
  overflow: hidden;
}

.hero-img {
  width: 625px;
  transition: all 0.8s;
}
.hero-img:hover {
  transform: scale(1.05);
}

.animate {
  transform: translateX(-100%);
}

.cta-consult p {
  font-family: "Lato", sans-serif;
  font-size: 5.2rem;
  font-weight: 900;
  line-height: 1.2;
  padding-bottom: 6.4rem;
}
.hero-cta:link,
.hero-cta:visited {
  background-color: #00bff2;
  color: #fff;
  text-decoration: none;
  font-size: 3rem;
  font-weight: 700;
  padding: 2.4rem 3.2rem;
  border-radius: 11px;
  box-shadow: 0 1.2rem 2.4rem rgba(0, 0, 0, 0.2);
  transition: all 0.3s;
}

.hero-cta:hover,
.hero-cta:active {
  box-shadow: 0 2.4rem 4.8rem rgba(0, 0, 0, 0.3);
}

/* ////////////////////////////////////////////////////////// */
/* Services Section */
/* ////////////////////////////////////////////////////////// */

.services {
  padding: 0 0 9.6rem 0;
}

.services-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  text-align: center;
  gap: 4.8rem;
  padding: 0 4.8rem 9.6rem;
}

.services-container a:link,
.services-container a:visited {
  text-decoration: none;
  color: black;
}

.service {
  box-shadow: 0 1.2rem 2.4rem rgba(82, 82, 82, 0.1);
  padding-top: 1.2rem;
  cursor: pointer;
}

.service p {
  font-size: 1.6rem;
  line-height: 1.6;
  letter-spacing: 0.5px;
  padding: 1rem;
}

.service-icon {
  width: 8rem;
  height: 8rem;
}

/* ////////////////////////////////////////////////////////// */
/* Additional Services Section */
/* ////////////////////////////////////////////////////////// */

.additional-services {
  display: flex;
  justify-content: space-around;
  padding: 0 4.8rem;
}

.additional-services-card {
  height: 20rem;
  width: 35rem;
  padding: 1.2rem;
  box-shadow: 0 1.2rem 2.4rem rgba(0, 0, 0, 0.1);
  background-color: #ccf2fc;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: all 0.3s;
}

.additional-services-card:hover {
  transform: scale(1.03);
}

.add-services-head {
  padding-bottom: 2rem;
  font-size: 2.4rem;
  font-weight: 700;
}

.add-services {
  list-style: none;
  align-items: center;
  display: flex;
  flex-direction: column;
}

.add-service {
  padding-bottom: 1.2rem;
  font-size: 1.6rem;
}
.add-services li:last-child {
  padding-bottom: 2.4rem;
}

.add-service-btn:link,
.add-service-btn:visited {
  text-decoration: none;
  font-size: 1.6rem;
  font-weight: 600;
  padding: 0.8rem 1rem;
  border: none;
  border-radius: 9px;
  color: #000225;
  background-color: #00bff2;
  cursor: pointer;
  transition: all 0.3s;
  box-shadow: 0 1.2rem 2.4rem rgba(0, 0, 0, 0.1);
  letter-spacing: 1px;
}

.add-service-btn:hover {
  color: #ccf2fc;
  background-color: #00bff2;
}

/* ////////////////////////////////////////////////////////// */
/* Encourage Section / CTA2 */
/* ////////////////////////////////////////////////////////// */

.encourage {
  font-size: 4.4rem;
  font-weight: 600;
  background-image: linear-gradient(
      rgba(255, 255, 255, 0.5),
      rgba(255, 255, 255, 0.5)
    ),
    url(../img/keyboard-crop.webp);
  min-height: 16rem;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}

.caption {
  position: absolute;
  left: 0;
  top: 40%;
  width: 100%;
  text-align: center;
}

/* ////////////////////////////////////////////////////////// */
/* Facts Section */
/* ////////////////////////////////////////////////////////// */

.facts {
  padding-bottom: 9.8rem;
}

.facts-header {
  padding-top: 4.8rem;
}

.facts-container {
  display: flex;
  justify-content: space-evenly;
  gap: 4.8rem;
  padding: 4.8rem 4.8rem 8rem;
}

.fact {
  max-width: 30ch;
  font-size: 1.8rem;
  line-height: 1.3;
  display: flex;
  flex-direction: column;
}

.fact-heading {
  align-self: center;
  font-size: 3.6rem;
  font-weight: 600;
  padding-bottom: 1.2rem;
}

.rating {
  text-align: center;
  padding-bottom: 2rem;
}

.graph-img {
  width: 25%;
}

/* ////////////////////////////////////////////////////////// */
/* Contact Us Section */
/* ////////////////////////////////////////////////////////// */

.contact-us-section {
  padding: 4.8rem 0 12.8rem;
}

.contact-us-container {
  max-width: 130rem;
  margin: 0 auto;
  padding: 0 3.2rem;
}

.contact-us {
  display: grid;
  grid-template-columns: 2fr 1fr;
  padding: 2.4rem;
  background-image: linear-gradient(#ccf2fc, #b3ecfb);
  border-radius: 11px;
  box-shadow: 0 1.2rem 2.4rem rgba(0, 0, 0, 0.1);
}

.form {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 3.2rem;
  row-gap: 2.4rem;
}

.form label {
  display: block;
  font-size: 1.8rem;
  font-weight: 600;
  margin-bottom: 0.8rem;
}

.form input,
.form select {
  padding: 1.2rem;
  width: 100%;
  font-size: 1.8rem;
  font-family: inherit;
  border: 1px solid #00bff2;
  border-radius: 11px;
}

textarea {
  grid-column: 1/-1;
  font-family: inherit;
  padding: 1.2rem;
  border: 1px solid #00bff2;
  border-radius: 11px;
  font-size: 1.8rem;
}

.btn--form {
  border: none;
  cursor: pointer;
  font-size: 2.4rem;
  font-weight: 600;
  color: #000225;
  background-color: #ccf2fc;
  padding: 1.2rem;
  border-radius: 11px;
  width: 50%;
  transition: all 0.3s;
  box-shadow: 0 1.2rem 2.4rem rgba(0, 0, 0, 0.1);
}

.btn--form:hover {
  color: #fff;
  background-color: #00bff2;
}

.contact-img {
  width: 100%;
  margin: 1.2rem;
  box-shadow: 0 1.2rem 2.4rem rgba(0, 0, 0, 0.15);
  border-radius: 11px;
}

/* .btn--form {
  grid-row: 4;
} */

/* ////////////////////////////////////////////////////////// */
/* Footer Section */
/* ////////////////////////////////////////////////////////// */

.copyright {
  font-size: 2rem;
  text-align: center;
  padding-bottom: 1rem;
}

/*
  FONT SIZE SYSTEM (px)
  10 / 12 / 14 / 16 / 18 / 20 / 24 / 30 / 36 / 44 / 52 / 62 / 74 / 86 / 98
   
  SPACING SYSTEM (px)
  2 / 4 / 8 / 12 / 16 / 24 / 32 / 48 / 64 / 80 / 96 / 128
  */

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  scroll-behavior: smooth;
  font-size: 62.5%;
  overflow-x: hidden;
}

body {
  color: #000;
  background-color: #edfbff;
  font-family: Epilogue, sans-serif;
  font-weight: 400;
  line-height: 1;
  overflow-x: hidden;
}

h2 {
  padding: 0 0 4.4rem 8rem;
  font-size: 3rem;
  font-weight: 700;
}

h3 {
  padding-top: 1.2rem;
  font-size: 2.4rem;
  font-weight: 700;
}

h4 {
  font-size: 2rem;
}

.section {
  transition: transform .3s, opacity 1s;
}

.section--hidden {
  opacity: 0;
  transform: translateY(8rem);
}

.btn-mobile-nav {
  display: none;
}

.header {
  justify-content: space-between;
  display: flex;
  position: relative;
}

.nav {
  height: 6.4rem;
  align-items: center;
  padding: 0 4.8rem;
  display: flex;
}

.logo {
  height: 6.2rem;
  padding: .8rem .8rem .8rem 2.4rem;
}

.nav-items {
  text-transform: uppercase;
  gap: 4.8rem;
  list-style: none;
  display: flex;
}

.header.sticky {
  width: 100%;
  z-index: 100000000;
  background-color: #edfbff;
  border-bottom: 1px solid #929497;
  position: fixed;
}

.nav-item:link, .nav-item:visited {
  color: #004c61;
  letter-spacing: 1px;
  font-size: 2.4rem;
  font-weight: 500;
  text-decoration: none;
}

.nav-item:hover, .nav-item:active {
  color: #001318;
}

.hero-section {
  max-width: 90vw;
  margin: 0 auto;
  padding: 4.8rem 0 9.6rem;
}

.hero {
  grid-template-columns: 1fr 1fr;
  place-items: start center;
  gap: 9.6rem;
  padding: 4.4rem 3.2rem;
  display: grid;
}

.hero-graphic-box {
  overflow: hidden;
}

.hero-img {
  width: 625px;
  transition: all .8s;
}

.hero-img:hover {
  transform: scale(1.05);
}

.animate {
  transform: translateX(-100%);
}

.cta-consult p {
  padding-bottom: 6.4rem;
  font-family: Lato, sans-serif;
  font-size: 5.2rem;
  font-weight: 900;
  line-height: 1.2;
}

.hero-cta:link, .hero-cta:visited {
  color: #fff;
  background-color: #00bff2;
  border-radius: 11px;
  padding: 2.4rem 3.2rem;
  font-size: 3rem;
  font-weight: 700;
  text-decoration: none;
  transition: all .3s;
  box-shadow: 0 1.2rem 2.4rem #0003;
}

.hero-cta:hover, .hero-cta:active {
  box-shadow: 0 2.4rem 4.8rem #0000004d;
}

.services {
  padding: 0 0 9.6rem;
}

.services-container {
  text-align: center;
  grid-template-columns: repeat(3, 1fr);
  gap: 4.8rem;
  padding: 0 4.8rem 9.6rem;
  display: grid;
}

.services-container a:link, .services-container a:visited {
  color: #000;
  text-decoration: none;
}

.service {
  cursor: pointer;
  padding-top: 1.2rem;
  box-shadow: 0 1.2rem 2.4rem #5252521a;
}

.service p {
  letter-spacing: .5px;
  padding: 1rem;
  font-size: 1.6rem;
  line-height: 1.6;
}

.service-icon {
  width: 8rem;
  height: 8rem;
}

.additional-services {
  justify-content: space-around;
  padding: 0 4.8rem;
  display: flex;
}

.additional-services-card {
  height: 20rem;
  width: 35rem;
  background-color: #ccf2fc;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1.2rem;
  transition: all .3s;
  display: flex;
  box-shadow: 0 1.2rem 2.4rem #0000001a;
}

.additional-services-card:hover {
  transform: scale(1.03);
}

.add-services-head {
  padding-bottom: 2rem;
  font-size: 2.4rem;
  font-weight: 700;
}

.add-services {
  flex-direction: column;
  align-items: center;
  list-style: none;
  display: flex;
}

.add-service {
  padding-bottom: 1.2rem;
  font-size: 1.6rem;
}

.add-services li:last-child {
  padding-bottom: 2.4rem;
}

.add-service-btn:link, .add-service-btn:visited {
  color: #000225;
  cursor: pointer;
  letter-spacing: 1px;
  background-color: #00bff2;
  border: none;
  border-radius: 9px;
  padding: .8rem 1rem;
  font-size: 1.6rem;
  font-weight: 600;
  text-decoration: none;
  transition: all .3s;
  box-shadow: 0 1.2rem 2.4rem #0000001a;
}

.add-service-btn:hover {
  color: #ccf2fc;
  background-color: #00bff2;
}

.encourage {
  min-height: 16rem;
  background-image: linear-gradient(#ffffff80, #ffffff80), url("keyboard-crop.f7ecdf6a.webp");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  font-size: 4.4rem;
  font-weight: 600;
  position: relative;
}

.caption {
  width: 100%;
  text-align: center;
  position: absolute;
  top: 40%;
  left: 0;
}

.facts {
  padding-bottom: 9.8rem;
}

.facts-header {
  padding-top: 4.8rem;
}

.facts-container {
  justify-content: space-evenly;
  gap: 4.8rem;
  padding: 4.8rem 4.8rem 8rem;
  display: flex;
}

.fact {
  max-width: 30ch;
  flex-direction: column;
  font-size: 1.8rem;
  line-height: 1.3;
  display: flex;
}

.fact-heading {
  align-self: center;
  padding-bottom: 1.2rem;
  font-size: 3.6rem;
  font-weight: 600;
}

.rating {
  text-align: center;
  padding-bottom: 2rem;
}

.graph-img {
  width: 25%;
}

.contact-us-section {
  padding: 4.8rem 0 12.8rem;
}

.contact-us-container {
  max-width: 130rem;
  margin: 0 auto;
  padding: 0 3.2rem;
}

.contact-us {
  background-image: linear-gradient(#ccf2fc, #b3ecfb);
  border-radius: 11px;
  grid-template-columns: 2fr 1fr;
  padding: 2.4rem;
  display: grid;
  box-shadow: 0 1.2rem 2.4rem #0000001a;
}

.form {
  grid-template-columns: repeat(2, 1fr);
  gap: 2.4rem 3.2rem;
  display: grid;
}

.form label {
  margin-bottom: .8rem;
  font-size: 1.8rem;
  font-weight: 600;
  display: block;
}

.form input, .form select {
  width: 100%;
  border: 1px solid #00bff2;
  border-radius: 11px;
  padding: 1.2rem;
  font-family: inherit;
  font-size: 1.8rem;
}

textarea {
  border: 1px solid #00bff2;
  border-radius: 11px;
  grid-column: 1 / -1;
  padding: 1.2rem;
  font-family: inherit;
  font-size: 1.8rem;
}

.btn--form {
  cursor: pointer;
  color: #000225;
  width: 50%;
  background-color: #ccf2fc;
  border: none;
  border-radius: 11px;
  padding: 1.2rem;
  font-size: 2.4rem;
  font-weight: 600;
  transition: all .3s;
  box-shadow: 0 1.2rem 2.4rem #0000001a;
}

.btn--form:hover {
  color: #fff;
  background-color: #00bff2;
}

.contact-img {
  width: 100%;
  border-radius: 11px;
  margin: 1.2rem;
  box-shadow: 0 1.2rem 2.4rem #00000026;
}

.copyright {
  text-align: center;
  padding-bottom: 1rem;
  font-size: 2rem;
}

/*# sourceMappingURL=index.8e604d92.css.map */
